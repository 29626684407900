import React from 'react'
import './board.css'
import Image from 'gatsby-image'
import { Link } from 'gatsby'

const BoardMember = ({
  id,
  role,
  name,
  image,
  description,
  slug,
  font_color,
  bg_color,
  font_family,
  font_size,
  font_weight,
  email,
}) => {
  const MAX_LENGTH = 100
  let isValid = description ? true : false

  return (
    <div className="col-md-4">
      <Link to={isValid ? `/board/${slug}` : null} key={id}>
        <div
          className="team-player"
          style={{ backgroundColor: bg_color.color }}
        >
          {image && (
            <Image
              fluid={image.childImageSharp.fluid}
              className="img-fluid img-raised"
            />
          )}{' '}
          <p
            className="category"
            style={{
              color: font_color.color,
              fontFamily: font_family.family,
              margin: '0px',
            }}
          >
            {name}{' '}
          </p>{' '}
          <p
            style={{
              color: font_color.color,
              fontFamily: font_family.family,
              fontSize: font_size.size,
              margin: '0px',
            }}
          >
            {role}{' '}
          </p>{' '}
          <p
            style={{
              margin: '0px',
            }}
          >
            {' '}
            {email}{' '}
          </p>{' '}
          <p
            className="description half-size"
            style={{
              color: font_color.color,
              fontFamily: font_family.family,
              fontSize: font_size.size,
            }}
          >
            {' '}
            {isValid && `${description.substring(0, MAX_LENGTH)}` + '...'}{' '}
            {isValid && (
              <button className="board-read-btn" style={{ marginTop: '0px' }}>
                Read more{' '}
              </button>
            )}{' '}
          </p>{' '}
        </div>{' '}
      </Link>{' '}
    </div>
  )
}

export default BoardMember
