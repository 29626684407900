import React from 'react'
import './board.css'

import Board from './Board'

export const BoardMembers = ({ members, title, showLink }) => {
  // debugger
  return (
    <div className="box center card board_card ">
      <h2 className="heading"> {title} </h2>{' '}
      <div className="box center ">
        <h2
          style={{ color: '#000', fontWeight: '600', fontFamily: 'Arial',fontSize:'28px' }}
          className="heading"
        >
          Board Members{' '}
        </h2>{' '}
        <div className="container">
          <div className="row">
            {' '}
            {members.map((member, index) => {
              return <Board key={index} {...member} />
            })}{' '}
          </div>{' '}
        </div>{' '}
      </div>{' '}
    </div>
  )
}
export default BoardMembers
